import { render, staticRenderFns } from "./ListChannelItem.vue?vue&type=template&id=fe7301e8&scoped=true&"
import script from "./ListChannelItem.vue?vue&type=script&lang=js&"
export * from "./ListChannelItem.vue?vue&type=script&lang=js&"
import style0 from "./ListChannelItem.vue?vue&type=style&index=0&id=fe7301e8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe7301e8",
  null
  
)

export default component.exports